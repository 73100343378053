import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import api from '../api';
import './OrderConfirmation.css';

function OrderConfirmation() {
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { orderId } = useParams();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const navigate = useNavigate();

    useEffect(() => {
        if (!orderId || !token) {
            navigate('/');
            return;
        }

        api.get(`/api/orders/${orderId}?token=${token}`)
            .then(response => {
                setOrder(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching order:', error);
                if (error.response?.status === 403) {
                    setError('This order link has expired for security reasons. Please check your email for order details.');
                } else {
                    setError('Unable to load order details');
                }
                setLoading(false);
            });
    }, [orderId, token, navigate]);

    if (loading) return (
        <div className="order-confirmation-container">
            <div className="loading">Loading order details...</div>
        </div>
    );

    if (error) return (
        <div className="order-confirmation-container">
            <div className="error">{error}</div>
        </div>
    );

    if (!order) return (
        <div className="order-confirmation-container">
            <div className="error">Order not found</div>
        </div>
    );

    return (
        <div className="order-confirmation-container">
            <div className="order-confirmation-content">
                <h1>Thank you for your order!</h1>
                <div className="order-details">
                    <h2>Order #{order.id}</h2>
                    <p className="confirmation-message">
                        We've received your order and will process it shortly.
                        A confirmation email has been sent to {order.email}.
                    </p>

                    <div className="order-summary">
                        <h3>Order Summary</h3>
                        <div className="items-list">
                            {order.items.map((item, index) => (
                                <div key={index} className="order-item">
                                    <span className="item-name">{item.product_name}</span>
                                    <span className="item-quantity">x{item.quantity}</span>
                                    <span className="item-price">£{parseFloat(item.product_price).toFixed(2)}</span>
                                </div>
                            ))}
                        </div>
                        <div className="order-total">
                            <span>Total</span>
                            <span>£{parseFloat(order.total).toFixed(2)}</span>
                        </div>
                    </div>

                    <div className="delivery-details">
                        <h3>Delivery Details</h3>
                        <p>{order.first_name} {order.last_name}</p>
                        <p>{order.address}</p>
                        <p>{order.city}</p>
                        <p>{order.postcode}</p>
                    </div>

                    <div className="actions">
                        <button 
                            className="continue-shopping"
                            onClick={() => navigate('/shop')}
                        >
                            Continue Shopping
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderConfirmation; 