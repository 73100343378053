import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import './Funerals.css';

function Funeral() {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [setActiveService] = useState(null);

    const funeralServices = [
        {
            icon: "🌹",
            title: "Casket Sprays",
            description: "Elegant floral arrangements designed to rest gracefully on the casket."
        },
        {
            icon: "💐",
            title: "Standing Sprays",
            description: "Beautiful standing arrangements to honor and commemorate your loved one."
        },
        {
            icon: "🌸",
            title: "Sympathy Bouquets",
            description: "Thoughtful arrangements for family and friends to express their condolences."
        },
        {
            icon: "🌿",
            title: "Memorial Tributes",
            description: "Custom designs that celebrate and honor the life of your loved one."
        }
    ];

    // const testimonials = [
    //     {
    //         text: "During our difficult time, Bluebells provided such caring and professional service. The arrangements were beautiful and brought us comfort.",
    //         name: "The Wilson Family",
    //         date: "2023"
    //     },
    //     {
    //         text: "The attention to detail and compassionate service meant so much to our family. The flowers were exactly what we hoped for.",
    //         name: "The Thompson Family",
    //         date: "2023"
    //     },
    //     {
    //         text: "Thank you for helping us honor our father with such beautiful arrangements. Your guidance was invaluable.",
    //         name: "The Roberts Family",
    //         date: "2023"
    //     }
    // ];

    const funeralImages = [
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/gallery/Funeral/055452A9-ED85-49A6-B91D-D233C627976E.jpeg",
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/gallery/Funeral/22E5A4AF-9A64-4932-8559-7DE7C27C04B7.jpeg",
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/gallery/Funeral/4B997662-1BC0-48CB-A8D5-78D941A6D749_1_105_c.jpeg",
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/gallery/Funeral/623EFEB0-B803-4550-9FEC-10BF9BF230E2_1_105_c.jpeg",
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/gallery/Funeral/DF72A68A-2FE9-4A93-8497-2A7F1AFCF697_4_5005_c.jpeg",
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/gallery/Funeral/6DD080C1-FAE8-4D06-A067-59922B7159F7_1_201_a.jpg"
    ];

    return (
        <div className="service-page funeral-page">
            <div className="service-container">
                <header className="service-header">
                    <h1>Funeral & Memorial Flowers</h1>
                    <h2>Honoring Your Loved Ones with Grace and Dignity</h2>
                </header>

                <div className="service-description funeral-description">
                    <div className="description-text">
                        <p>Organising a funeral can be a challenging task, but we're here to offer assistance and support. We understand the importance of honoring your loved one's life and are committed to accommodating any special requests you may have. The designs showcased on our website serve as inspiration and can be customised to incorporate various flowers and colours, allowing you to create a truly unique and personalised tribute.</p>
                        <p>We offer casket sprays, wreaths, letters, pillows and bespoke designs. We will go over your favourite colours and flowers, and no matter how unique your ideas are, we are here to make them a reality.</p>
                        <Link to="/contact" className="contact-button funeral-contact">
                            Contact Us for Assistance
                            <span className="button-arrow">→</span>
                        </Link>
                    </div>
                    <div className="description-image">
                        <img src="https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/gallery/Funeral/F3E919D1-2E9A-4FE3-8160-ED3178CC1D0E_1_201_a.jpg" alt="Funeral flower arrangement" />
                    </div>
                </div>

                <div className="services-section">
                    <h2>Our Memorial Services</h2>
                    <div className="services-grid">
                        {funeralServices.map((service, index) => (
                            <div 
                                key={index} 
                                className="service-card"
                            >
                                <div className="service-icon">{service.icon}</div>
                                <h3>{service.title}</h3>
                                <p>{service.description}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="gallery-section">
                    <h2 className="gallery-title">Memorial Gallery</h2>
                    <div className="gallery-grid">
                        {funeralImages.map((image, index) => (
                            <div 
                                key={index} 
                                className="gallery-item"
                                onClick={() => {
                                    setPhotoIndex(index);
                                    setIsOpen(true);
                                }}
                            >
                                <img src={image} alt={`Memorial arrangement ${index + 1}`} />
                                <div className="gallery-overlay">
                                    <span>View Arrangement</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <Lightbox
                    open={isOpen}
                    close={() => setIsOpen(false)}
                    slides={funeralImages.map(src => ({ src }))}
                    index={photoIndex}
                />

                {/* <div className="testimonials-section funeral-testimonials">
                    <h2>Family Testimonials</h2>
                    <div className="testimonials-grid">
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="testimonial-card">
                                <div className="quote-mark">❝</div>
                                <p className="testimonial-text">{testimonial.text}</p>
                                <div className="testimonial-author">
                                    <div className="author-info">
                                        <h4>{testimonial.name}</h4>
                                        <p>{testimonial.date}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}

                <div className="contact-section">
                    <div className="contact-content">
                        <h2>Need Assistance?</h2>
                        <p>We're here to help you during this difficult time. Please don't hesitate to reach out.</p>
                        <Link to="/contact" className="contact-button funeral-contact">
                            Contact Us
                            <span className="button-arrow">→</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Funeral;