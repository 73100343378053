import React, { useState, useEffect } from 'react';
import { useCart } from '../context/CartContext';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckoutForm';
import api from '../api';
import './Checkout.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function Checkout() {
    const [clientSecret, setClientSecret] = useState(null);
    const { cart } = useCart();
    const navigate = useNavigate();

    const getItemPrice = (item) => {
        if (item.variation) {
            return item.variation.price;
        }
        return item.product.price;
    };

    const formatPrice = (price) => {
        return `£${parseFloat(price).toFixed(2)}`;
    };

    useEffect(() => {
        const getClientSecret = async () => {
            try {
                const response = await api.get(`/api/payment/check_intent/`, {
                    params: { cart_id: cart.id }
                });
                setClientSecret(response.data.client_secret);
            } catch (error) {
                console.error('Error getting payment intent:', error);
                navigate('/cart');
            }
        };

        if (cart?.id) {
            getClientSecret();
        }
    }, [cart, navigate]);

    if (!clientSecret) {
        return <div>Loading...</div>;
    }

    const options = {
        clientSecret,
        appearance: {
            theme: 'stripe',
            labels: 'floating'
        },
        locale: 'en-GB',
        elements: {
            address: {
                mode: 'billing',
                defaultValues: {
                    country: 'GB',
                }
            }
        }
    };

    return (
        <div className="checkout-container">
            {clientSecret && (
                <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm 
                        clientSecret={clientSecret}
                        formatPrice={formatPrice}
                        getItemPrice={getItemPrice}
                    />
                </Elements>
            )}
        </div>
    );
}

export default Checkout;