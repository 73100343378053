import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import './Workshops.css';

function Workshops() {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [activeWorkshop, setActiveWorkshop] = useState('christmas-2023');

    const workshops = {
        'christmas-2023': {
            title: "Christmas Wreaths 2024",
            date: "December 2024",
            description: "Christmas Wreath making workshop at Ashton Fruit Shop.",
            images: [
                "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/workshop-1.jpeg",
                "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/workshop-2.jpeg",
                "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/workshop-3.jpeg",
            ]
        },
        // Add more workshops
    };

    const upcomingWorkshops = [
        {
            id: 'christmas-2023',
            title: "Christmas Wreath Making",
            date: "December 15, 2023",
            time: "6:30 PM - 8:30 PM",
            price: "£45",
            spots: "0 spots left",
            description: "Join us for a festive and fun-filled Christmas Wreath Workshop! Get creative and craft your own beautiful wreath while enjoying the holiday spirit with mulled wine, soft drinks, nibbles, and delicious mince pies. The workshop is £45, with a £20 deposit required to secure your spot and the remaining £25 balance due by November 20th. Perfect for adding a personal touch to your holiday décor!",
            image: "https://visit.houseofmarbles.com/wp-content/uploads/2024/09/EV-Christmas-Wreath-Making.jpg"
        },
        // Add more workshops
    ];

    return (
        <div className="workshops-page">
            <div className="workshops-container">
                <header className="workshops-header">
                    <h1>Floral Workshops</h1>
                    <h2>Learn, Create, and Bloom with Us</h2>
                </header>

                <div className="workshops-intro">
                    <div className="intro-text">
                        <p>Our floral workshops are a wonderful way to learn the art of flower arranging in a fun, relaxed setting. Whether you're a beginner or looking to sharpen your skills, our workshops cater to all levels and include seasonal themes. Each session is hands-on and guided, allowing you to create stunning arrangements using fresh, locally-sourced flowers. We provide all materials, so all you need to bring is your creativity! Perfect for groups, friends, or solo participants, our workshops also make a fantastic gift. Join us for an inspiring experience, and leave with a beautiful arrangement and newfound confidence in your floral skills.</p>
                        <Link to="/contact" className="workshop-contact-button">
                            Enquire About Workshops
                            <span className="button-arrow">→</span>
                        </Link>
                    </div>
                    <div className="intro-image">
                        <img src="https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/workshop-inprogress.jpeg" 
                             alt="Workshop in progress" />
                    </div>
                </div>

                <section className="upcoming-workshops">
                    <h2>Upcoming Workshops</h2>
                    <div className="workshops-grid">
                        {upcomingWorkshops.map((workshop) => (
                            <div key={workshop.id} className="workshop-card">
                                <div className="workshop-image">
                                    <img src={workshop.image} alt={workshop.title} />
                                </div>
                                <div className="workshop-details">
                                    <h3>{workshop.title}</h3>
                                    <p className="workshop-date">{workshop.date} | {workshop.time}</p>
                                    <p className="workshop-price">{workshop.price}</p>
                                    <p className="workshop-spots">{workshop.spots}</p>
                                    <p>{workshop.description}</p>
                                    <button 
                                        className="book-button"
                                        disabled={workshop.spots === "0 spots left"}
                                    >
                                        {workshop.spots === "0 spots left" ? "Fully Booked" : "Book Now"}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>

                <section className="workshop-gallery">
                    <h2>Past Workshop Gallery</h2>
                    <div className="gallery-tabs">
                        {Object.keys(workshops).map((workshopId) => (
                            <button 
                                key={workshopId}
                                className={`gallery-tab ${activeWorkshop === workshopId ? 'active' : ''}`}
                                onClick={() => setActiveWorkshop(workshopId)}
                            >
                                {workshops[workshopId].title}
                            </button>
                        ))}
                    </div>
                    
                    <div className="gallery-content">
                        <h3>{workshops[activeWorkshop].title}</h3>
                        <p className="gallery-date">{workshops[activeWorkshop].date}</p>
                        <p className="gallery-description">{workshops[activeWorkshop].description}</p>
                        <div className="gallery-grid">
                            {workshops[activeWorkshop].images.map((image, index) => (
                                <div 
                                    key={index} 
                                    className="gallery-item"
                                    onClick={() => {
                                        setPhotoIndex(index);
                                        setIsOpen(true);
                                    }}
                                >
                                    <img src={image} alt={`Workshop moment ${index + 1}`} />
                                    <div className="gallery-overlay">
                                        <span>View Image</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <Lightbox
                    open={isOpen}
                    close={() => setIsOpen(false)}
                    slides={workshops[activeWorkshop].images.map(src => ({ src }))}
                    index={photoIndex}
                />

                <section className="workshop-contact">
                    <div className="contact-content">
                        <h2>Interested in Our Workshops?</h2>
                        <p>Join our mailing list to stay updated on upcoming workshops and early bird pricing.</p>
                        <Link to="/contact" className="contact-button">
                            Contact Us
                            <span className="button-arrow">→</span>
                        </Link>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Workshops;