import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import { CartProvider } from './context/CartContext';
import ScrollToTop from './components/ScrollToTop';

import Home from './pages/Home';
import About from './pages/About';
import Funerals from './pages/Funerals';
import Weddings from './pages/Weddings';
import Workshops from './pages/Workshops';
import Shop from './pages/Shop';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import ProductDetail from './pages/ProductDetail';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Gallery from './pages/Gallery';
import AdminGallery from './pages/AdminGallery';
import OrderConfirmation from './pages/OrderConfirmation';
import PaymentFailed from './pages/PaymentFailed';

function App() {
    return (
        <CartProvider>
            <Router>
                <ScrollToTop />
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/funerals" element={<Funerals />} />
                    <Route path="/weddings" element={<Weddings />} />
                    <Route path="/workshops" element={<Workshops />} />
                    <Route path="/shop" element={<Shop />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/shop/:id" element={<ProductDetail />} />
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/payment-failed" element={<PaymentFailed />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/gallery/album/:albumId" element={<Gallery />} />
                    <Route path="/admin/gallery" element={<AdminGallery />} />
                    <Route path="/order-confirmation/:orderId" element={<OrderConfirmation />} />
                    <Route path="/payment-failed" element={<PaymentFailed />} />
                </Routes>
                <Footer />
            </Router>
        </CartProvider>
    );
}

export default App;