import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import './Weddings.css';

function Wedding() {

    const seasons = [
        {
            name: "Spring",
            flowers: [
                { 
                    name: "Tulips", 
                    image: "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/Seasonal+-+Spring/anton-darius-0MEda5JdPTo-unsplash.jpg" 
                },
                { 
                    name: "Daffodils",
                  image: "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/Seasonal+-+Spring/heidi-samuelson-K_3EBdS8d_Q-unsplash.jpg" 
                },
                { 
                    name: "Cherry Blossoms", 
                    image: "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/Seasonal+-+Spring/tania-malrechauffe-I5hj8Dm0h8k-unsplash.jpg" 
                },
                { 
                    name: "Anemones", 
                    image: "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/Seasonal+-+Spring/zoe-richardson-Sbcv2zS6GRY-unsplash.jpg" 
                }
            ]
        },
        {
            name: "Summer",
            flowers: [
                { 
                    name: "Peonies", 
                    image: "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/Seasonal+-+Summer/rebecca-_C9XM02Y5ZE-unsplash+(1).jpg" 
                },
                { 
                    name: "Hydrangeas", 
                    image: "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/Seasonal+-+Summer/sei-5pJ1XUmbG1A-unsplash+(1).jpg"  
                },
                { 
                    name: "Garden Roses", 
                    image: "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/Seasonal+-+Summer/stephanie-mulrooney-3qWUXN1gHqQ-unsplash.jpg" 
                },
                { 
                    name: "Cosmos", 
                    image: "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/Seasonal+-+Summer/yoksel-zok-IknMt0hEwSo-unsplash.jpg" 
                }
            ]
        },
        {
            name: "Autumn",
            flowers: [
                { 
                    name: "Zinnias", 
                    image: "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/Seasonal+-+Autumn/amber-malquist-ykyGnY3If2w-unsplash.jpg" 
                },
                { 
                    name: "Chrysanthemums", 
                    image: "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/Seasonal+-+Autumn/anca-gabriela-zosin-I84tlLmvRJQ-unsplash+(1).jpg" 
                },
                { 
                    name: "Dahlias", 
                    image: "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/Seasonal+-+Autumn/catrina-farrell-79Qa_Zr-iEs-unsplash.jpg" 
                },
                { 
                    name: "Japanese Anemones", 
                    image: "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/Seasonal+-+Autumn/tadeusz-zachwieja-32TwV3JujYk-unsplash.jpg" 
                }
            ]
        },
        {
            name: "Winter",
            flowers: [
                { 
                    name: "Anemones", 
                    image: "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/Seasonal+-+Winter/8kka-ame-BZAmgyKmZvY-unsplash.jpg" 
                },
                { 
                    name: "Amaryllis", 
                    image: "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/Seasonal+-+Winter/lucas-van-oort-SOKol2ekjC0-unsplash.jpg" 
                },
                { 
                    name: "Hellebores", 
                    image: "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/Seasonal+-+Winter/misky-Ott_2TBuIX0-unsplash.jpg" 
                },
                { 
                    name: "Ranunculus", 
                    image: "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/Seasonal+-+Winter/olga-m-aHd9toK6Gcw-unsplash.jpg" 
                }
            ]
        }
    ];

    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [activeService, setActiveService] = useState(null);
    const [activeSeason, setActiveSeason] = useState('Spring');
    const [isVisible, setIsVisible] = useState(false);
    const [currentSeason, setCurrentSeason] = useState(seasons[0]);

    const weddingServices = [
        {
            icon: "💐",
            title: "Bridal Bouquets",
            description: "Stunning, personalized bouquets that complement your style and dress."
        },
        {
            icon: "🌺",
            title: "Ceremony Flowers",
            description: "Beautiful arrangements for your altar, aisle, and venue decoration."
        },
        {
            icon: "🌸",
            title: "Reception Decor",
            description: "Elegant centerpieces and decorative elements for your reception tables."
        },
        {
            icon: "🎀",
            title: "Personal Flowers",
            description: "Boutonnieres, corsages, and flower crowns for your wedding party."
        }
    ];

    const testimonials = [
        {
            text: "Bluebells created the most beautiful arrangements for our wedding. Every detail was perfect!",
            name: "Sarah & James",
            date: "Summer 2023",
            image: "https://images.unsplash.com/photo-1519741497674-611481863552?w=800&auto=format&fit=crop"
        },
        {
            text: "The team understood our vision perfectly. The flowers were absolutely stunning!",
            name: "Emma & Michael",
            date: "Spring 2023",
            image: "https://images.unsplash.com/photo-1515934751635-c81c6bc9a2d8?w=800&auto=format&fit=crop"
        },
        {
            text: "Professional, creative and a joy to work with. Our wedding flowers were a dream come true.",
            name: "Sophie & Thomas",
            date: "Autumn 2023",
            image: "https://images.unsplash.com/photo-1519742866993-66d3cfef4bbd?w=800&auto=format&fit=crop"
        }
    ];

    const processSteps = [
        {
            title: "Initial Consultation",
            description: "We'll discuss your vision, style, and preferences over a relaxed consultation."
        },
        {
            title: "Design Proposal",
            description: "We'll create a detailed proposal including flower selections and mood boards."
        },
        {
            title: "Final Planning",
            description: "We'll refine the details and confirm all arrangements for your special day."
        },
        {
            title: "Wedding Day",
            description: "We'll handle all setup and styling, ensuring everything is perfect."
        }
    ];

    useEffect(() => {
        setIsVisible(true);
    }, []);

    useEffect(() => {
        const selected = seasons.find(season => season.name === activeSeason);
        setCurrentSeason(selected);
    }, [activeSeason]);

    const featuredImage = "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/8C6601FB-5EB3-4D4C-9533-9DFBCC1920E4_1_201_a.jpeg";
    const weddingImages = [
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/2B5246E2-9C4B-4D6E-B5B6-AC03B528166A_4_5005_c.jpeg",
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/5FA52165-4CB1-47AB-9E06-D1523C7064FB_1_105_c.jpeg",
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/7FCF8155-FCE6-4902-8912-AE0C6CD308EB_1_105_c.jpeg",
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/B0A80D78-345C-404C-BD84-B46BF5BAA929_1_105_c.jpeg",
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/D20E9DCC-8BC0-4306-A872-20C6378894B0_1_102_a.jpeg",
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/staticimages/Weddings/1D32020C-8FD8-41A4-B508-03C08AB7E10B_1_105_c.jpeg"
    ];

    return (
        <div className="service-page">
            <div className="service-container">
                {/* Header Section */}
                <header className="service-header">
                    <h1>Wedding Flowers</h1>
                    <h2>Creating Beautiful Moments for Your Special Day</h2>
                </header>

                <div className="service-description">
                    <div className="description-text">
                        <p>At Bluebells, we understand that your wedding flowers are an essential part of your special day. We work closely with you to create beautiful, bespoke floral arrangements that perfectly match your vision and style.</p>
                        
                        <p>From elegant bouquets to stunning ceremony arrangements and reception centerpieces, we ensure every detail is perfect. Our experienced team will guide you through the entire process, from initial consultation to final delivery.</p>
                        
                        <div className="services-grid">
                            {weddingServices.map((service, index) => (
                                <div 
                                    key={index}
                                    className={`service-card ${activeService === index ? 'active' : ''}`}
                                    onMouseEnter={() => setActiveService(index)}
                                    onMouseLeave={() => setActiveService(null)}
                                >
                                    <div className="service-icon">
                                        {service.icon}
                                    </div>
                                    <div className="service-card-content">
                                        <h3>{service.title}</h3>
                                        <p>{service.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="description-image hover-effect">
                        <img src={featuredImage} alt="Wedding flowers arrangement" />
                    </div>
                </div>

                <div className="wedding-process">
                    <h2>Our Wedding Flow</h2>
                    <div className="timeline">
                        {processSteps.map((step, index) => (
                            <div className="timeline-item" key={index}>
                                <div className="timeline-number">{index + 1}</div>
                                <div className="timeline-content">
                                    <h3>{step.title}</h3>
                                    <p>{step.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="gallery-section">
                    <h2 className="gallery-title">Our Wedding Gallery</h2>
                    <div className="gallery-grid">
                        {weddingImages.map((image, index) => (
                            <div 
                                key={index} 
                                className="gallery-item"
                                onClick={() => {
                                    setPhotoIndex(index);
                                    setIsOpen(true);
                                }}
                            >
                                <img src={image} alt={`Wedding arrangement ${index + 1}`} />
                                <div className="gallery-overlay">
                                    <span>View Image</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <Lightbox
                    open={isOpen}
                    close={() => setIsOpen(false)}
                    slides={weddingImages.map(src => ({ src }))}
                    index={photoIndex}
                />

                <div className="seasonal-section">
                    <h2>Seasonal Wedding Flowers</h2>
                    <div className="seasons-tabs">
                        {seasons.map((season) => (
                            <button 
                                key={season.name}
                                className={`season-tab ${activeSeason === season.name ? 'active' : ''}`}
                                onClick={() => setActiveSeason(season.name)}
                            >
                                {season.name}
                            </button>
                        ))}
                    </div>
                    <div className="season-content">
                        <div className="season-flowers">
                            {currentSeason.flowers.map((flower, index) => (
                                <div className="flower-item" key={index}>
                                    <img src={flower.image} alt={flower.name} />
                                    <h4>{flower.name}</h4>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="wave-testimonials">
                    <div className="wave-top"></div>
                    <div className="testimonials-section">
                        <h2>What Our Couples Say</h2>
                        <div className="testimonials-grid">
                            {testimonials.map((testimonial, index) => (
                                <div key={index} className="testimonial-card">
                                    <div className="quote-mark">❝</div>
                                    <p className="testimonial-text">{testimonial.text}</p>
                                    <div className="testimonial-author">
                                        <div className="author-image">
                                            <img src={testimonial.image} alt={testimonial.name} />
                                        </div>
                                        <div className="author-info">
                                            <h4>{testimonial.name}</h4>
                                            <p>{testimonial.date}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="wave-bottom"></div>
                </div>

                <div className="contact-section">
                    <div className="contact-content">
                        <h2>Ready to Discuss Your Wedding Flowers?</h2>
                        <p>Let's create something beautiful together for your special day</p>
                        <Link to="/contact" className="wedding-contact">
                            Get in Touch
                            <span className="button-arrow">→</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Wedding;