import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useCart } from '../context/CartContext';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import './CheckoutForm.css';

const cardStyle = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4"
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
};

function CheckoutForm({ clientSecret }) {
    const stripe = useStripe();
    const elements = useElements();
    const { cart, clearCart } = useCart();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [customerDetails, setCustomerDetails] = useState({
        email: '',
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        postcode: '',
        phone: ''
    });

    console.log("Cart data:", cart);
    console.log("Cart items:", cart?.items);

    const handleInputChange = (e) => {
        setCustomerDetails({
            ...customerDetails,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setProcessing(true);
        setError(null);

        // Validate customer details
        const requiredFields = ['email', 'firstName', 'lastName', 'address', 'city', 'postcode', 'phone'];
        const missingFields = requiredFields.filter(field => !customerDetails[field]);
        if (missingFields.length > 0) {
            setError(`Please fill in all required fields: ${missingFields.join(', ')}`);
            setProcessing(false);
            return;
        }

        if (!stripe || !elements) {
            setError("Stripe hasn't loaded yet. Please try again.");
            setProcessing(false);
            return;
        }

        try {
            // Get a fresh client secret
            const intentResponse = await api.get(`/api/payment/check_intent/`, {
                params: { cart_id: cart.id }
            });
            
            console.log("Got fresh client secret:", intentResponse.data);

            const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(
                intentResponse.data.client_secret,
                {
                    payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            name: `${customerDetails.firstName} ${customerDetails.lastName}`,
                            email: customerDetails.email,
                            phone: customerDetails.phone,
                            address: {
                                line1: customerDetails.address,
                                city: customerDetails.city,
                                postal_code: customerDetails.postcode,
                                country: 'GB',
                            }
                        }
                    }
                }
            );

            if (confirmError) {
                console.error('Confirmation error:', confirmError);
                setError(`Payment failed: ${confirmError.message}`);
                setProcessing(false);
                return;
            }

            if (paymentIntent.status === 'succeeded') {
                try {
                    // Create order with snake_case field names
                    const orderResponse = await api.post('/api/orders/', {
                        payment_intent_id: paymentIntent.id,
                        cart_id: cart.id,
                        email: customerDetails.email,
                        first_name: customerDetails.firstName,
                        last_name: customerDetails.lastName,
                        address: customerDetails.address,
                        city: customerDetails.city,
                        postcode: customerDetails.postcode,
                        phone: customerDetails.phone
                    });
                    
                    console.log('Order creation response:', orderResponse.data);

                    if (orderResponse.data.order_id || orderResponse.data.id) {
                        await clearCart();
                        navigate(`/order-confirmation/${orderResponse.data.id}?token=${orderResponse.data.access_token}`);
                    } else {
                        throw new Error('No order ID in response');
                    }
                } catch (orderError) {
                    console.error('Order creation error:', orderError.response?.data);
                    const errorMessage = orderError.response?.data?.error 
                        ? typeof orderError.response.data.error === 'object'
                            ? 'Please fill in all required fields'
                            : orderError.response.data.error
                        : 'Failed to create order. Please contact support.';
                    setError(errorMessage);
                    setProcessing(false);
                }
            } else {
                setError(`Payment status: ${paymentIntent.status}. Please try again.`);
                setProcessing(false);
            }
        } catch (err) {
            console.error('Payment error:', err);
            setError('An error occurred while processing your payment. Please try again.');
        }
        
        setProcessing(false);
    };

    const getImageUrl = (imageUrl) => {
        if (!imageUrl) return '/placeholder-image.jpg';
        if (imageUrl.startsWith('http')) {
            return imageUrl.replace('/products/', '/media/products/');
        }
        const cleanImagePath = imageUrl.replace(/^\/+/, '');
        return `${process.env.REACT_APP_S3_URL}/media/${cleanImagePath}`;
    };

    const OrderSummary = ({ cart, getItemPrice, formatPrice }) => {
        return (
            <div className="order-summary">
                <h2>Order Summary</h2>
                {cart.items.map((item) => (
                    <div key={item.id} className="order-item">
                        <img src={item.product.image} alt={item.product.name} />
                        <div className="item-details">
                            <h3>
                                {item.product.name}
                                {item.variation && (
                                    <span className="variation-name"> - {item.variation.size}</span>
                                )}
                            </h3>
                            <p>{formatPrice(getItemPrice(item))}</p>
                            <p>Quantity: {item.quantity}</p>
                        </div>
                    </div>
                ))}
                <div className="order-total">
                    <p>Total: {formatPrice(cart.total)}</p>
                </div>
            </div>
        );
    };

    return (
        <div className="checkout-form-container">
            <div className="order-summary">
                <h3>Order Summary</h3>
                {cart?.items?.map((item) => (
                    <div key={item.id} className="order-item">
                        <div className="item-image">
                            {item.product_details?.image ? (
                                <img src={getImageUrl(item.product_details.image)} alt={item.product_details.name} />
                            ) : (
                                <div className="placeholder-image">No Image</div>
                            )}
                        </div>
                        <div className="item-details">
                            <h4>
                                {item.product_details?.name || 'Unnamed Product'}
                                {item.variation_details && (
                                    <span className="variation-name"> - {item.variation_details.size}</span>
                                )}
                            </h4>
                            <p className="item-price">
                                £{item.variation_details 
                                    ? parseFloat(item.variation_details.price).toFixed(2)
                                    : parseFloat(item.product_details?.price || 0).toFixed(2)
                                }
                            </p>
                            <p className="item-quantity">Quantity: {item.quantity}</p>
                        </div>
                    </div>
                ))}
                <div className="order-total">
                    <p>Total: £{cart?.total}</p>
                </div>
            </div>
            <form onSubmit={handleSubmit} className="payment-form">
                <div className="customer-details">
                    <h3>Contact Details</h3>
                    <div className="form-row">
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={customerDetails.email}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-row two-cols">
                        <input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            value={customerDetails.firstName}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            value={customerDetails.lastName}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-row">
                        <textarea
                            name="address"
                            placeholder="Address"
                            value={customerDetails.address}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-row two-cols">
                        <input
                            type="text"
                            name="city"
                            placeholder="City"
                            value={customerDetails.city}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="postcode"
                            placeholder="Postcode"
                            value={customerDetails.postcode}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-row">
                        <input
                            type="tel"
                            name="phone"
                            placeholder="Phone Number"
                            value={customerDetails.phone}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
                <div className="payment-details">
                    <h3>Payment Details</h3>
                    <div className="form-row">
                        <CardElement options={cardStyle} />
                    </div>
                </div>
                {error && (
                    <div className="card-error" role="alert">
                        {error}
                    </div>
                )}
                <button 
                    className="submit-button" 
                    disabled={!stripe || processing}
                >
                    {processing ? (
                        <div className="spinner"></div>
                    ) : (
                        `Pay £${cart?.total}`
                    )}
                </button>
            </form>
        </div>
    );
}

export default CheckoutForm; 