import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BsCart3 } from 'react-icons/bs';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useCart } from '../context/CartContext';
import './Navbar.css';

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const { cart } = useCart();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const totalItems = cart?.items?.reduce((sum, item) => sum + item.quantity, 0) || 0;

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <div className="logo-container">
                    <Link to="/" className="navbar-logo">
                        <h1>bluebells</h1>
                        <p>floral studio</p>
                    </Link>
                </div>
                
                <div className="mobile-menu-icon" onClick={toggleMenu}>
                    {isOpen ? <FaTimes /> : <FaBars />}
                </div>

                <div className={`nav-menu ${isOpen ? 'active' : ''}`}>
                    <Link to="/funerals" className="nav-link" onClick={() => setIsOpen(false)}>Funerals</Link>
                    <Link to="/weddings" className="nav-link" onClick={() => setIsOpen(false)}>Weddings</Link>
                    <Link to="/workshops" className="nav-link" onClick={() => setIsOpen(false)}>Workshops</Link>
                    <Link to="/gallery" className="nav-link" onClick={() => setIsOpen(false)}>Gallery</Link>
                    <Link to="/shop" className="nav-link" onClick={() => setIsOpen(false)}>Shop</Link>
                    <Link to="/contact" className="nav-link" onClick={() => setIsOpen(false)}>Get in Touch</Link>
                    <Link to="/cart" className="nav-link cart-icon" onClick={() => setIsOpen(false)}>
                        <BsCart3 />
                        <span className="cart-count">{totalItems}</span>
                    </Link>
                    {isAdmin && (
                        <Link to="/admin/gallery" className="nav-link" onClick={() => setIsOpen(false)}>
                            Manage Gallery
                        </Link>
                    )}
                </div>
            </div>
        </nav>
    );
}

export default Navbar;