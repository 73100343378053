import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useCart } from '../context/CartContext';
import './ProductDetail.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ProductDetail() {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [quantity, setQuantity] = useState(1);
    const [selectedVariation, setSelectedVariation] = useState(null);
    const { addToCart } = useCart();

    const getImageUrl = (imageUrl) => {
        console.log('Original image URL:', imageUrl);
        
        if (!imageUrl) {
            console.log('No image URL provided');
            return '/placeholder-image.jpg';
        }
        
        // If it's already a full URL, modify the path
        if (imageUrl.startsWith('http')) {
            console.log('Modifying full URL path');
            return imageUrl.replace('/products/', '/media/products/');
        }
        
        // Remove any leading slashes and add media prefix
        const cleanImagePath = imageUrl.replace(/^\/+/, '');
        const fullUrl = `${process.env.REACT_APP_S3_URL}/media/${cleanImagePath}`;
        console.log('Constructed S3 URL:', fullUrl);
        return fullUrl;
    };

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/${id}/`);
                setProduct(response.data);
                if (response.data.variations && response.data.variations.length > 0) {
                    setSelectedVariation(response.data.variations[0]);
                }
                setLoading(false);
            } catch (err) {
                console.error('Error fetching product:', err);
                setLoading(false);
            }
        };
        fetchProduct();
    }, [id]);

    const handleAddToCart = async () => {
        const success = await addToCart(
            product.id,
            quantity,
            selectedVariation ? selectedVariation.id : null
        );
        
        if (success) {
            toast.success('Added to cart!');
        } else {
            toast.error('Failed to add to cart');
        }
    };

    const getDisplayPrice = () => {
        if (product.has_variations) {
            if (selectedVariation) {
                return `£${selectedVariation.price}`;
            }
            return `£${product.price_range.min} - £${product.price_range.max}`;
        }
        return `£${product.price}`;
    };

    const getMaxQuantity = () => {
        const availableStock = product.has_variations 
            ? (selectedVariation?.stock || 0) 
            : product.stock;
        return Math.min(10, availableStock);
    };

    if (loading) return <div>Loading...</div>;
    if (!product) return <div>Product not found</div>;

    return (
        <div className="product-detail-container">
            <div className="product-main">
                <div className="product-detail-image">
                    <img 
                        src={getImageUrl(product.image)} 
                        alt={product.name}
                        onError={(e) => {
                            const imgUrl = e.target.src;
                            console.error('Image load error for URL:', imgUrl);
                            console.error('Product data:', product);
                            e.target.onerror = null; // Prevent infinite loop
                            e.target.src = `${process.env.REACT_APP_API_URL}/static/placeholder-image.jpg`;
                        }}
                    />
                </div>
                <div className="product-detail-info">
                    <h1 className="product-title">{product.name}</h1>
                    <p className="price">{getDisplayPrice()}</p>
                    
                    {product.has_variations && (
                        <div className="variation-selector">
                            <label>Size:</label>
                            <select
                                value={selectedVariation?.id || ''}
                                onChange={(e) => {
                                    const variation = product.variations.find(
                                        v => v.id === Number(e.target.value)
                                    );
                                    setSelectedVariation(variation);
                                }}
                            >
                                <option value="">Select Size</option>
                                {product.variations.map(variation => (
                                    <option 
                                        key={variation.id} 
                                        value={variation.id}
                                        disabled={variation.stock === 0}
                                    >
                                        {variation.size}
                                        {variation.stock === 0 ? ' (Out of Stock)' : ''}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    <div className="quantity-selector">
                        <label>Quantity:</label>
                        <select 
                            value={quantity} 
                            onChange={(e) => setQuantity(Number(e.target.value))}
                        >
                            {[...Array(getMaxQuantity())].map((_, i) => (
                                <option key={i + 1} value={i + 1}>{i + 1}</option>
                            ))}
                        </select>
                    </div>

                    <button 
                        className="add-to-cart-btn"
                        onClick={handleAddToCart}
                        disabled={product.has_variations ? 
                            !selectedVariation || selectedVariation.stock === 0 : 
                            !product.stock}
                    >
                        {product.has_variations ? 
                            (!selectedVariation ? 'Select Size' : 
                             selectedVariation.stock === 0 ? 'Out of Stock' : 'Add to Cart') : 
                            (product.stock ? 'Add to Cart' : 'Out of Stock')}
                    </button>

                    <div className="product-meta">
                        <p>Category: {product.category_name}</p>
                        {/* <p>Stock: {product.has_variations ? 
                            (selectedVariation?.stock || 0) : 
                            product.stock} available</p> */}
                        <p className="description">{product.description}</p>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default ProductDetail;