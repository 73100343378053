import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../api';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState({ items: [], total: 0 });

    const fetchCart = async () => {
        try {
            const cartId = localStorage.getItem('cartId');
            console.log('Fetching cart with ID:', cartId);
            
            const response = await api.get('/api/cart/', {
                params: { cart_id: cartId }
            });
            
            console.log('Cart response:', response.data);
            
            if (response.data.id) {
                localStorage.setItem('cartId', response.data.id);
                setCart(response.data);
            }
        } catch (error) {
            console.error('Error fetching cart:', error.response?.data || error.message);
        }
    };

    const addToCart = async (productId, quantity = 1, variationId = null) => {
        try {
            const cartId = localStorage.getItem('cartId');
            const response = await api.post('/api/cart/add_item/', {
                cart_id: cartId,
                product_id: productId,  // Ensure this is correct
                quantity: quantity,
                variation_id: variationId
            });
            
            if (response.data.id) {
                localStorage.setItem('cartId', response.data.id);
                setCart(response.data);
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error adding to cart:', error.response?.data || error.message);
            return false;
        }
    };

    const removeFromCart = async (itemId) => {
        try {
            const cartId = localStorage.getItem('cartId');
            console.log('Removing item:', { cartId, itemId });
            
            const response = await api.post('/api/cart/remove_item/', {
                cart_id: cartId,
                item_id: itemId
            });
            
            console.log('Remove response:', response.data);
            setCart(response.data);
        } catch (error) {
            console.error('Error removing from cart:', error);
            await fetchCart();
        }
    };

    const updateQuantity = async (itemId, quantity) => {
        try {
            const cartId = localStorage.getItem('cartId');
            const response = await api.post('/api/cart/update_quantity/', {
                cart_id: cartId,
                item_id: itemId,
                quantity: quantity
            });
            
            // Check if response.data has the expected structure
            if (response.data.items) {
                setCart(response.data);
            } else if (response.data.cart) {
                setCart(response.data.cart);
            } else {
                console.error('Unexpected response structure:', response.data);
                await fetchCart(); // Fallback to fetching the cart
            }
        } catch (error) {
            console.error('Error updating quantity:', error.response?.data || error.message);
            await fetchCart(); // Fallback to fetching the cart
        }
    };

    const clearCart = () => {
        setCart(null);
        localStorage.removeItem('cartId');
    };

    useEffect(() => {
        fetchCart();
    }, []);

    return (
        <CartContext.Provider value={{ 
            cart, 
            addToCart, 
            removeFromCart, 
            updateQuantity,
            clearCart 
        }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext);