import React, { useState, useEffect } from 'react';
import './Contact.css';
import api from '../api';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        enquiryType: '',
        message: ''
    });
    const [status, setStatus] = useState({ type: '', message: '' });

    useEffect(() => {
        if (status.message) {
            const timer = setTimeout(() => {
                setStatus({ type: '', message: '' });
            }, 10000); // 10 seconds

            return () => clearTimeout(timer);
        }
    }, [status]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus({ type: 'loading', message: 'Sending...' });
        
        try {
            const response = await api.post('/api/contact/', formData);
            if (response.data.status === 'success') {
                setStatus({ 
                    type: 'success', 
                    message: 'Thank you for your message. We will get back to you soon!' 
                });
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    enquiryType: '',
                    message: ''
                });
            }
        } catch (error) {
            setStatus({ 
                type: 'error', 
                message: 'Sorry, something went wrong. Please try again later.' 
            });
            console.error('Error sending message:', error.response?.data || error);
        }
    };

    const closeMessage = () => {
        setStatus({ type: '', message: '' });
    };

    return (
        <div className="contact-page">
            {status.message && (
                <div className={`status-message ${status.type}`}>
                    <span>{status.message}</span>
                    <button className="close-button" onClick={closeMessage}>×</button>
                    <div className="timer-bar"></div>
                </div>
            )}
            <div className="contact-container">
                <div className="contact-info">
                    <h1>Get in Touch</h1>
                    <h2>Let's create something beautiful together</h2>
                    
                    <div className="info-content">
                        <p>We'd love to hear from you! Whether you're planning a wedding, need funeral flowers, or just want to brighten someone's day with a beautiful bouquet, we're here to help.</p>
                        
                        <div className="contact-details">
                            <div className="detail-item">
                                <span className="icon">📍</span>
                                <div className="locations">
                                    <div className="location">
                                        <h3>Home Studio</h3>
                                        <p>Whitchurch, Bristol, BS14</p>
                                    </div>
                                    <div className="location">
                                        <h3>Ashton FruitShop</h3>
                                        <p>269 North Street, Bristol, BS3 1JN</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="detail-item">
                                <span className="icon">📞</span>
                                <div>
                                    <h3>Phone</h3>
                                    <p>07388 349811</p>
                                </div>
                            </div>
                            
                            <div className="detail-item">
                                <span className="icon">✉️</span>
                                <div>
                                    <h3>Email</h3>
                                    <p>hello@bluebellsfloralstudio.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="phone">Phone Number</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="enquiryType">Type of Enquiry</label>
                            <select
                                id="enquiryType"
                                name="enquiryType"
                                value={formData.enquiryType}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Choose an option</option>
                                <option value="wedding">Wedding Enquiry</option>
                                <option value="event">Event Enquiry</option>
                                <option value="bouquet">Bouquet Enquiry</option>
                                <option value="general">General Enquiry</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                rows="5"
                            ></textarea>
                        </div>

                        <button type="submit" className="submit-button">
                            Send Message
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Contact;