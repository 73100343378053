import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import './Shop.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Shop() {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { cart, addToCart } = useCart();
    const navigate = useNavigate();

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/`);
            console.log('Products:', response.data);
            setProducts(response.data);
            setLoading(false);
        } catch (err) {
            console.error('Error:', err);
            setError('Failed to fetch products');
            setLoading(false);
        }
    };

    const handleAddToCart = async (productId) => {
        try {
            const success = await addToCart(productId, 1); // Pass parameters directly
            if (success) {
                toast.success('Added to cart!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    style: {
                        background: 'white',
                        color: '#5A6A88',
                        boxShadow: '0 4px 12px rgba(90, 106, 136, 0.15)',
                    },
                    progressStyle: {
                        background: '#5A6A88'
                    }
                });
            } else {
                toast.error('Failed to add to cart');
            }
        } catch (err) {
            console.error('Failed to add item to cart:', err);
            toast.error('Failed to add to cart');
        }
    };

    const truncateDescription = (description, maxLength = 100) => {
        if (!description) return '';
        if (description.length <= maxLength) return description;
        return description.slice(0, maxLength) + '...';
    };

    const getImageUrl = (imageUrl) => {
        console.log('Original image URL:', imageUrl);
        
        if (!imageUrl) {
            console.log('No image URL provided');
            return '/placeholder-image.jpg';
        }
        
        // If it's already a full URL, modify the path
        if (imageUrl.startsWith('http')) {
            console.log('Modifying full URL path');
            return imageUrl.replace('/products/', '/media/products/');
        }
        
        // Remove any leading slashes and add media prefix
        const cleanImagePath = imageUrl.replace(/^\/+/, '');
        const fullUrl = `${process.env.REACT_APP_S3_URL}/media/${cleanImagePath}`;
        console.log('Constructed S3 URL:', fullUrl);
        return fullUrl;
    };

    const handleGoToCart = () => {
        navigate('/cart');
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="shop-container">
            <h1>Shop</h1>
            <div className="cart-info">
                <p>Items in cart: {cart.items.reduce((sum, item) => sum + item.quantity, 0)} | Total: £{cart.total}</p>
                <button className="go-to-cart-btn" onClick={handleGoToCart}>
                    Go to Cart
                </button>
            </div>
            <div className="products-grid">
                {products.map(product => (
                    <div key={product.id} className="product-card">
                        <Link to={`/shop/${product.id}`}>
                            <img 
                                src={getImageUrl(product.image)}
                                alt={product.name} 
                                onError={(e) => {
                                    const imgUrl = e.target.src;
                                    console.error('Image load error for URL:', imgUrl);
                                    console.error('Product data:', product);
                                    e.target.onerror = null; // Prevent infinite loop
                                    e.target.src = `${process.env.REACT_APP_API_URL}/static/placeholder-image.jpg`;
                                }}
                                className="product-image"
                            />
                            <h3>{product.name}</h3>
                            <p className="price">
                                {product.has_variations ? (
                                    product.price_range ? 
                                    `£${product.price_range.min} - £${product.price_range.max}` :
                                    'Price varies'
                                ) : (
                                    `£${product.price}`
                                )}
                            </p>
                        </Link>
                        {product.has_variations ? (
                            <Link to={`/shop/${product.id}`}>
                                <button>Choose Options</button>
                            </Link>
                        ) : (
                            <button 
                                disabled={product.stock === 0}
                                onClick={() => handleAddToCart(product.id)}
                            >
                                {product.stock === 0 ? 'Out of Stock' : 'Add to Cart'}
                            </button>
                        )}
                    </div>
                ))}
            </div>
            <ToastContainer />
        </div>
    );
}

export default Shop;