import React, { useState, useEffect } from 'react';
import api from '../api';
import { toast } from 'react-toastify';
import './AdminGallery.css';

function AdminGallery() {
    const [albums, setAlbums] = useState([]);
    const [newAlbumTitle, setNewAlbumTitle] = useState('');
    const [newAlbumDescription, setNewAlbumDescription] = useState('');
    const [selectedAlbum, setSelectedAlbum] = useState(null);
    const [files, setFiles] = useState([]);
    const [featuredImage, setFeaturedImage] = useState(null);

    useEffect(() => {
        fetchAlbums();
    }, []);

    const fetchAlbums = async () => {
        try {
            const response = await api.get('/albums/');
            setAlbums(response.data);
        } catch (error) {
            console.error('Error fetching albums:', error);
            toast.error('Failed to fetch albums');
        }
    };

    const handleFileSelect = (e) => {
        if (e.target.files) {
            const validFiles = Array.from(e.target.files).filter(file => {
                const isImage = file.type.startsWith('image/');
                const isVideo = file.type.startsWith('video/');
                return isImage || isVideo;
            });
            setFiles(validFiles);
        }
    };

    const createAlbum = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('title', newAlbumTitle);
            formData.append('description', newAlbumDescription);
            
            const response = await api.post('/albums/', formData);
            setNewAlbumTitle('');
            setNewAlbumDescription('');
            fetchAlbums();
            toast.success('Album created successfully');
            return response.data;
        } catch (error) {
            console.error('Error creating album:', error);
            toast.error('Failed to create album');
        }
    };

    const uploadImages = async (e) => {
        e.preventDefault();
        if (!selectedAlbum || (!files.length && !featuredImage)) return;

        const formData = new FormData();
        files.forEach(file => {
            formData.append('images', file);
        });
        if (featuredImage) {
            formData.append('featured_image', featuredImage);
        }

        try {
            await api.post(`/albums/${selectedAlbum}/upload_images/`, formData);
            setFiles([]);
            setFeaturedImage(null);
            fetchAlbums();
            toast.success('Images uploaded successfully');
        } catch (error) {
            console.error('Error uploading images:', error);
            toast.error('Failed to upload images');
        }
    };

    return (
        <div className="admin-gallery-container">
            <h1>Gallery Management</h1>
            
            <section className="create-album-section">
                <h2>Create New Album</h2>
                <form onSubmit={createAlbum}>
                    <input
                        type="text"
                        placeholder="Album Title"
                        value={newAlbumTitle}
                        onChange={(e) => setNewAlbumTitle(e.target.value)}
                        required
                    />
                    <textarea
                        placeholder="Album Description"
                        value={newAlbumDescription}
                        onChange={(e) => setNewAlbumDescription(e.target.value)}
                    />
                    <button type="submit">Create Album</button>
                </form>
            </section>

            <section className="upload-images-section">
                <h2>Upload Images</h2>
                <form onSubmit={uploadImages}>
                    <select
                        value={selectedAlbum || ''}
                        onChange={(e) => setSelectedAlbum(e.target.value)}
                        required
                    >
                        <option value="">Select Album</option>
                        {albums.map(album => (
                            <option key={album.id} value={album.id}>
                                {album.title}
                            </option>
                        ))}
                    </select>
                    
                    <div className="upload-section">
                        <label>Featured Image:</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setFeaturedImage(e.target.files[0])}
                        />
                    </div>

                    <div className="upload-section">
                        <label>Bulk Upload Images & Videos:</label>
                        <input
                            type="file"
                            multiple
                            accept="image/*,video/*,.heic"
                            onChange={handleFileSelect}
                        />
                        <small>Supported formats: Images (JPG, PNG, GIF, HEIC) and Videos (MP4, WebM)</small>
                    </div>

                    <button type="submit">Upload Images</button>
                </form>
            </section>
        </div>
    );
}

export default AdminGallery; 