import React from 'react';
import { useCart } from '../context/CartContext';
import './Cart.css';
import { useNavigate } from 'react-router-dom';

function Cart() {
    const { cart, removeFromCart, updateQuantity, clearCart } = useCart();
    const navigate = useNavigate();

    console.log('Cart state:', cart);

    const getImageUrl = (imageUrl) => {
        if (!imageUrl) return '/placeholder-image.jpg';
        if (imageUrl.startsWith('http')) {
            return imageUrl.replace('/products/', '/media/products/');
        }
        const cleanImagePath = imageUrl.replace(/^\/+/, '');
        return `${process.env.REACT_APP_S3_URL}/media/${cleanImagePath}`;
    };

    const handleUpdateQuantity = async (itemId, newQuantity) => {
        try {
            console.log('Updating quantity:', { itemId, newQuantity });
            await updateQuantity(itemId, newQuantity);
        } catch (err) {
            console.error('Failed to update quantity:', err);
        }
    };

    const handleRemoveFromCart = async (itemId) => {
        try {
            console.log('Removing item:', itemId);
            await removeFromCart(itemId);
        } catch (err) {
            console.error('Failed to remove item:', err);
        }
    };

    if (!cart || !cart.items || cart.items.length === 0) {
        return (
            <div className="cart-container">
                <h1>Shopping Cart</h1>
                <p>Your cart is empty</p>
            </div>
        );
    }

    const handleCheckout = () => {
        navigate('/checkout');
    };

    const handleClearCart = async () => {
        try {
            await clearCart();
        } catch (err) {
            console.error('Failed to clear cart:', err);
        }
    };

    return (
        <div className="cart-container">
        <div className="cart-header">
            <h1>Shopping Cart</h1>
            <button className="empty-cart-btn" onClick={handleClearCart}>
                Empty Cart
            </button>
            </div>
            <div className="cart-items">
                {cart.items.map(item => (
                    <div key={item.id} className="cart-item">
                        <img 
                            src={getImageUrl(item.product_details.image)} 
                            alt={item.product_details.name}
                            onError={(e) => {
                                console.error('Image load error:', e);
                                e.target.src = '/placeholder-image.jpg';
                            }}
                        />
                        <div className="item-details">
                            <h3>{item.product_details.name}</h3>
                            {item.variation_details && (
                                <p>Size: {item.variation_details.size}</p>
                            )}
                            <p>£{item.variation_details ? item.variation_details.price : item.product_details.price}</p>
                        </div>
                        <div className="quantity-controls">
                            <select
                                value={item.quantity}
                                onChange={(e) => handleUpdateQuantity(item.id, Number(e.target.value))}
                            >
                                {[...Array(10)].map((_, i) => (
                                    <option key={i + 1} value={i + 1}>
                                        {i + 1}
                                    </option>
                                ))}
                            </select>
                            <button 
                                className="remove-btn"
                                onClick={() => handleRemoveFromCart(item.id)}
                            >
                                Remove
                            </button>
                        </div>
                        <div className="item-total">
                            £{(item.variation_details ? 
                                item.variation_details.price * item.quantity : 
                                item.product_details.price * item.quantity).toFixed(2)}
                        </div>
                    </div>
                ))}
            </div>
            <div className="cart-summary">
                <h2>Total: £{cart.total}</h2>
                <div className="cart-buttons">
                    <button className="checkout-btn" onClick={handleCheckout}>
                        Proceed to Checkout
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Cart;