import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../api';
import Lightbox from 'yet-another-react-lightbox';
import Video from "yet-another-react-lightbox/plugins/video";
import './Gallery.css';

function Gallery() {
    const [albums, setAlbums] = useState([]);
    const [selectedAlbum, setSelectedAlbum] = useState(null);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const { albumId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAlbums = async () => {
            try {
                const response = await api.get('/api/albums/');
                const processedAlbums = response.data.map(album => ({
                    ...album,
                    featured_image: album.featured_image && !album.featured_image.includes('/media/')
                        ? album.featured_image.replace('gallery/', 'media/gallery/')
                        : album.featured_image,
                    images: album.images.map(image => ({
                        ...image,
                        file_url: image.file_url && !image.file_url.includes('/media/')
                            ? image.file_url.replace('gallery/', 'media/gallery/')
                            : image.file_url
                    }))
                }));
                setAlbums(processedAlbums);
                console.log('Processed albums:', processedAlbums);
            } catch (error) {
                console.error('Error fetching albums:', error);
            }
        };

        fetchAlbums();
    }, []);

    useEffect(() => {
        if (albumId && albums.length > 0) {
            const album = albums.find(a => a.id.toString() === albumId);
            if (album) {
                setSelectedAlbum(album);
            }
        }
    }, [albumId, albums]);

    const openAlbum = (album) => {
        navigate(`/gallery/album/${album.id}`);
        setSelectedAlbum(album);
    };

    const closeAlbum = () => {
        navigate('/gallery');
        setSelectedAlbum(null);
    };

    const openLightbox = (imageIndex) => {
        setLightboxIndex(imageIndex);
        setLightboxOpen(true);
    };

    const getLightboxSlides = (images) => {
        return images.map(item => {
            if (item.media_type === 'video') {
                return {
                    type: "video",
                    width: 1280,
                    height: 720,
                    sources: [{ src: item.file_url, type: "video/mp4" }]
                };
            }
            return { src: item.file_url };
        });
    };

    const PlayIcon = () => (
        <svg viewBox="0 0 24 24">
            <path d="M8 5v14l11-7z"/>
        </svg>
    );

    const generateVideoThumbnail = (videoUrl) => {
        return videoUrl.replace(/\.(mov|mp4)$/, '.jpg');
    };

    console.log('Current albums:', albums);
    console.log('Selected album:', selectedAlbum);

    if (selectedAlbum) {
        return (
            <div className="gallery-container">
                <button className="back-button" onClick={closeAlbum}>
                    ← Back to Albums
                </button>
                
                <div className="album-header">
                    {(selectedAlbum.featured_image_url || selectedAlbum.images[0]?.file_url) && (
                        <div 
                            className="featured-image-container"
                            onClick={() => openLightbox(0)}
                        >
                            <img 
                                src={selectedAlbum.featured_image_url || selectedAlbum.images[0]?.file_url} 
                                alt={selectedAlbum.title}
                            />
                        </div>
                    )}
                    
                    <div className="album-info-section">
                        <h2>{selectedAlbum.title}</h2>
                        {selectedAlbum.description && (
                            <p className="album-description">{selectedAlbum.description}</p>
                        )}
                    </div>
                </div>

                <div className="image-grid">
                    {selectedAlbum.images.map((item, index) => (
                        <div 
                            key={item.id} 
                            className="gallery-image"
                            onClick={() => openLightbox(index)}
                        >
                            {item.media_type === 'video' ? (
                                <>
                                    <img 
                                        src={generateVideoThumbnail(item.file_url)}
                                        alt={item.caption || ''}
                                        className="video-thumbnail"
                                    />
                                    <div className="video-overlay">
                                        <PlayIcon />
                                    </div>
                                </>
                            ) : (
                                <img src={item.file_url} alt={item.caption || ''} />
                            )}
                        </div>
                    ))}
                </div>
                
                <Lightbox
                    open={lightboxOpen}
                    close={() => setLightboxOpen(false)}
                    index={lightboxIndex}
                    slides={getLightboxSlides(selectedAlbum.images)}
                    plugins={[Video]}
                />
            </div>
        );
    }

    return (
        <div className="gallery-container">
            <h1>Gallery</h1>
            {albums.length === 0 ? (
                <p>No albums found</p>
            ) : (
                <div className="albums-grid">
                    {albums.map(album => (
                        <div 
                            key={album.id} 
                            className="album-card"
                            onClick={() => openAlbum(album)}
                        >
                            {album.featured_image ? (
                                <img src={album.featured_image} alt={album.title} />
                            ) : (
                                album.images[0] && <img src={album.images[0].file_url} alt={album.title} />
                            )}
                            <div className="album-info">
                                <h3>{album.title}</h3>
                                <p>{album.images.length} photos</p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Gallery; 