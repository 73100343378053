import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PaymentFailed.css';

function PaymentFailed() {
    const navigate = useNavigate();

    return (
        <div className="payment-failed-container">
            <div className="payment-failed-content">
                <h1>Payment Failed</h1>
                <p>Unfortunately, your payment could not be processed at this time.</p>
                <p>Please try again or contact support if the issue persists.</p>
                <button 
                    className="retry-payment"
                    onClick={() => navigate('/checkout')}
                >
                    Retry Payment
                </button>
            </div>
        </div>
    );
}

export default PaymentFailed;