import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import './Home.css';
import bluebellImage from '../assets/bluebell.png';

function Home() {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const galleryImages = [
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/gallery/Home+page/7FCF8155-FCE6-4902-8912-AE0C6CD308EB_1_105_c.jpeg",
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/gallery/Home+page/BCE774F1-A92E-4DBF-BD7B-53B2E4BE026A_1_105_c.jpeg",
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/gallery/Home+page/DA8EE221-B928-48BE-B7F6-14E4A5BE2453_1_105_c.jpeg",
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/gallery/Home+page/DBD487DF-AC4A-460F-AB70-54F95BAA7AFA_1_105_c.jpeg",
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/gallery/Home+page/EBC76781-FBA1-43CE-8A3E-D47EBCF46DAF.jpeg",
        "https://bluebells-storage.s3.eu-west-2.amazonaws.com/media/gallery/Home+page/9F918E54-8961-47D8-8E84-565C691DEAE0_1_105_c.jpeg"
    ];

    return (
        <div className="home">
            <div className="hero-section">
                <div className="hero-content">
                <div className="hero-text">
                    <div className="title-group">
                        <h1>Bluebells</h1>
                        <h2>Floral Studio</h2>
                    </div>
                    <div className="hero-description">
                        <p>Welcome to Bluebells Floral Studio, your go-to destination in South Bristol for all your floristry needs. We specialise in creating stunning arrangements tailored to your unique style and sentiment.</p>
                        
                        <p>We offer all aspects of floristry including occasion and gift bouquets, weddings, funerals, events, installations and corporate. Please get in touch with any of your queries and I will get back to you, thanks a bunch!</p>
                        
                        <p className="delivery-note">Gift bouquets are available for delivery around the Bristol area, collection is also available in BS14.</p>
                    </div>
                </div>
                    <div className="hero-image-frame">
                        <div className="arch-decoration">
                            <div className="arch-inner">
                                <img src={bluebellImage} alt="Florist at work" />
                            </div>
                            <div className="arch-flowers top-left"></div>
                            <div className="arch-flowers top-right"></div>
                            <div className="arch-flowers bottom-left"></div>
                            <div className="arch-flowers bottom-right"></div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Shop Preview Section */}
            <div className="shop-preview">
                <div className="shop-content">
                    <h2>Online Shop</h2>
                    <p>Browse our selection of beautiful bouquets and floral arrangements available for local delivery or collection.</p>
                    <div className="shop-features">
                        <div className="feature">
                            <span className="feature-icon">🚚</span>
                            <p>Local Delivery Available</p>
                        </div>
                        <div className="feature">
                            <span className="feature-icon">📍</span>
                            <p>Collection from BS14</p>
                        </div>
                    </div>
                    <Link to="/shop" className="shop-button">Visit Shop</Link>
                </div>
            </div>

            {/* Opening Hours & Contact Section */}
            <div className="info-section">
                <div className="info-grid">
                    <div className="hours-card">
                        <h3>Opening Hours</h3>
                        <div className="hours-list">
                            <div className="hours-item">
                                <span>Monday - Friday</span>
                                <span>7:30 - 18:00</span>
                            </div>
                            <div className="hours-item">
                                <span>Saturday</span>
                                <span>8:00 - 16:00</span>
                            </div>
                            <div className="hours-item">
                                <span>Sunday</span>
                                <span>Closed</span>
                            </div>
                        </div>
                    </div>
                    <div className="contact-card">
                        <h3>Get in Touch</h3>
                        <p>Have a question or want to discuss your floral needs?</p>
                        <Link to="/contact" className="contact-button">Contact Us</Link>
                    </div>
                </div>
            </div>

            {/* Workshop Section */}
            <div className="workshop-section">
                <div className="workshop-content">
                    <h2>Floral Workshops</h2>
                    <p>Join us for hands-on floristry experiences where you'll learn techniques, get creative, and take home your own beautiful arrangements.</p>
                    <div className="workshop-features">
                        <div className="workshop-feature">
                            <h4>Beginner Friendly</h4>
                            <p>No experience needed</p>
                        </div>
                        <div className="workshop-feature">
                            <h4>Small Groups</h4>
                            <p>Personal attention</p>
                        </div>
                        <div className="workshop-feature">
                            <h4>All Materials</h4>
                            <p>Everything provided</p>
                        </div>
                    </div>
                    <Link to="/workshops" className="workshop-button">View Upcoming Workshops</Link>
                </div>
            </div>

            <div className="gallery-section">
                <h2>Our Recent Work</h2>
                <div className="gallery-grid">
                    {galleryImages.map((image, index) => (
                        <div 
                            key={index} 
                            className={`gallery-item ${index === 0 ? 'large' : ''}`}
                            onClick={() => {
                                setPhotoIndex(index);
                                setIsOpen(true);
                            }}
                        >
                            <img src={image} alt={`Gallery image ${index + 1}`} />
                        </div>
                    ))}
                </div>
                <Lightbox
                    open={isOpen}
                    close={() => setIsOpen(false)}
                    slides={galleryImages.map(src => ({ src }))}
                    index={photoIndex}
                />
                <Link to="/gallery" className="view-more-button">View Full Gallery</Link>
            </div>

            <div className="contact-banner">
                <div className="banner-waves"></div>
                <div className="banner-content">
                    <h2>Get in Touch</h2>
                    <p>Let's create something beautiful together</p>
                    <Link to="/contact" className="contact-button">Contact Us</Link>
                </div>
            </div>
        </div>
    );
}

export default Home;