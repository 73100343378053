import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaEnvelope } from 'react-icons/fa';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h3>Bluebells</h3>
                    <p>Floral Studio</p>
                    <div className="social-links">
                        <a href="https://www.facebook.com/profile.php?id=61555800475300" target="_blank" rel="noopener noreferrer">
                            <FaFacebook />
                        </a>
                        <a href="https://www.instagram.com/bluebellsfloralstudio" target="_blank" rel="noopener noreferrer">
                            <FaInstagram />
                        </a>
                        <a href="mailto:hello@bluebellsfloralstudio.com">
                            <FaEnvelope />
                        </a>
                    </div>
                </div>

                <div className="footer-section">
                    <h4>Quick Links</h4>
                    <Link to="/shop">Shop</Link>
                    <Link to="/gallery">Gallery</Link>
                    <Link to="/contact">Contact</Link>
                    <Link to="/workshops">Workshops</Link>
                </div>

                <div className="footer-section">
                    <h4>Services</h4>
                    <Link to="/weddings">Weddings</Link>
                    <Link to="/funerals">Funerals</Link>
                    <Link to="/events">Events</Link>
                </div>

                <div className="footer-section">
                    <h4>Contact</h4>
                    <p>Ashton Fruit Shop</p>
                    <p>269 North Street</p>
                    <p>Bristol, BS3 1JN</p>
                    <p>Tel: 07388 349811</p>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} Bluebells Floral Studio. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;